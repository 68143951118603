import React from 'react'
import { Title } from '../../components/Title'
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

export const Study = () => {

    const { t } = useTranslation();

  return (
    <>
        <section data-section-id="2" data-share="" data-category="features" data-component-id="4d2f33a1_05_awz" className="pt-16 pb-40 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
            <Title text={t('my_formation')} />
            <div className="flex flex-wrap xl:items-center -m-8 xl:-m-10">
                <div className="w-full md:w-1/2 xl:w-auto p-8 xl:p-10">
                <div className="block overflow-hidden rounded-3xl">
                    <img className="w-full transform hover:scale-105 transition ease-in-out duration-1000" src="src/img/santo-tomas.jpg" alt="" data-config-id="auto-img-1-5" />
                </div>
                </div>
                <div className="w-full md:w-1/2 xl:flex-1 p-8 xl:p-10">
                <div className="md:max-w-xl">
                    <div className="mb-11 pb-12 border-b">
                    <h3 className="text-lg font-semibold md:max-w-lg" data-config-id="auto-txt-2-5">{t('santo_tomas_info')}</h3>
                    </div>
                    <div className="flex flex-wrap -m-8">
                    <div className="w-auto md:w-1/2 p-8">
                        <div className="md:max-w-xs">
                        <h3 className="mb-4 text-lg text-indigo-600 font-semibold" data-config-id="auto-txt-5-5">{t('programmer_analyst')}</h3>
                        <p className="text-gray-900 font-medium" data-config-id="auto-txt-6-5">{t('santo_tomas_programmer')}</p>
                        </div>
                    </div>
                    <div className="w-auto md:w-1/2 p-8">
                        <div className="md:max-w-xs">
                        <h3 className="mb-4 text-lg text-indigo-600 font-semibold" data-config-id="auto-txt-3-5">{t('systems_analyst')}</h3>
                        <p className="text-gray-900 font-medium" data-config-id="auto-txt-4-5">{t('santo_tomas_analysts')}</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

    </>
  )
}
