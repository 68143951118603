import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  es: {
    translation: {
      spanish: 'Español',
      english: 'Ingles',
      home: 'Inicio',
      my_projects: 'Mis Projectos',
      my_projects_info: 'Aqui se encuentran publicados algunos proyectos en los que he trabajado, es posible que no esten todos pero trato de publicar todos los que pueda.',
      about_me: 'Sobre mí',
      contact: 'Contacto',
      need_assistance: 'Necesitas ayuda?',
      error_404: 'Error 404, pagina no encontrada!',
      error_title: 'Oops! Algo salio mal',
      back_home: 'Volver al inicio',

      title_presentation: 'Bienvenido a mi portfolio!',
      presentation: 'Gracias por visitar mi espacio digital. Aquí encontrarás una muestra de mi trabajo y proyectos de programación. Estoy emocionado de compartir contigo mi pasión por programar y cómo he aplicado mis habilidades para resolver desafíos.',
      
      hello_world: '👋 Hola mundo!',
      my_name: 'Eduardo Araya',
      about_me_content: 'Oriundo de la ciudad de Ovalle en Chile, soy egresado de Santo Tomas en la carrera de analista programador. Soy un gran apasionado por el area de tecnología y el desarrollo e implementación de lenguajes de programación.',
      about_me_quote: '“La pasión por lo desconocido es lo que nos ayuda a avanzar”',

      santo_tomas_info: 'Egresado en la carrera superior de analista programador, en su instituto profesional con sede en Ovalle.',
      santo_tomas_programmer: 'Diseño de software, analisis de requerimientos, programación avanzada, soporte informatico.',
      santo_tomas_analysts: 'Implementación y seguimiento, mantenimiento y actualización, evaluacion de tecnologias, gestión de proyectos.',
      systems_analyst: 'Analista de sistemas',
      udemy_info: 'Udemy ha sido una herramienta que me ha ayudado a potenciar mis conocimientos y adquirir nuevas habilidades.',
      visit_website: 'Visita su sitio web',
      my_formation: 'Mí formación',
      about_me_formation: 'Acerca de mi formación',
      about_me_subtitle: 'Mis principales fuentes de conocimiento',
      my_skills: 'Mis habilidades',
      about_skills: 'Capacidades principales que manejo',
      my_strengths: 'Mis fortalezas',
      strength_one_title: 'Pensamiento lógico',
      strength_one_description: 'Poseo un gran enfoque lógico a la hora de abordar problemas y diseñar una solución lo más efectiva posible.',
      strength_two_title: 'Resolución de problemas',
      strength_two_description: 'Tengo la capacidad de analizar y encontrar un problema complejos dentro de una solucion, utilizando herrameintas de debug para encontrar el nucleo del problema y resolverlo de raiz.',
      strength_three_title: 'Capacidad de aprendizaje',
      strength_three_description: 'Mantengo siempre un gran interes en las nuevas tecnologias emergentes y en las posibilidades de implementarlas o probarlas por mi persona.',
      strength_four_title: 'Gestión del tiempo',
      strength_four_description: 'Al haber trabajado en varios proyectos puedo tener una idea general del tiempo necesario para resolver distintos problemas y administrar el mismo en mi día a día.',
      
      my_weaknesses: 'Mis debilidades',
      weaknesses_one_title: 'Enfoque perfeccionista',
      weaknesses_one_description: 'En lo personal me encanta que las cosas queden perfectas, eso me lleva algunas veces a invertir mas tiempo de lo debido en alguna tarea.',
      weaknesses_two_title: 'Bajo interes en el diseño',
      weaknesses_two_description: 'El diseño siempre ha sido un problema, prefiero estar siempre detras de las lineas de codigo antes que el diseño o la experencia UI/UX, pero siempre trato de hacer mi mejor esfuerzo.',
      weaknesses_three_title: 'Exceso de autocritica',
      weaknesses_three_description: 'A veces puedo ser demasiado duro con mis codigos o llegar a sentir que no cumple con el estandar, por lo que siempre trato de seguir estructuras ya definidas en logicas de programación.',

      programmer_analyst: 'Analista programador',
      take_a_walk: 'Da un paseo por los distintos projectos en los que he trabajado.',
      wanna_see_more: '¿Quieres ver más?',
      see_all_projects_here: 'Ve todos mis projectos aquí!',
      some_languajes: 'Algunos lenguajes utilizados...',

      this_proyect_was_made: 'Este proyecto fue trabajado con las siguientes tecnologias y lenguajes de programación',
      about_this_proyect: 'Acerca de este proyecto de software',

      contact_me: 'Contactame',
      looking_for_me: 'Me estas buscando?',
      looking_for_me_info: 'Si estas buscando solicitar algun servicio con mi persona o estas interesado en ofrecerme algua propuesta puedes encontrarme en cualquiera de mis redes sociales.',
      
      assistance_data: 'Si tienes algún problema o estas buscando contactar de forma directa puedes escribirme directamente a mi correo personal:',
      personal_data: 'También puedes encontrarme en mi contacto personal:',
      send_message: 'Enviar mensaje',
      understood: 'Ok! entendido'
    
    },
  },
  en: {
    translation: {
      spanish: 'Spanish',
      english: 'English',
      home: 'Home',
      my_projects: 'My Projects',
      my_projects_info: 'Some projects that I have worked on are published here, it is possible that not all of them are there but I try to publish as many as I can.',
      about_me: 'About me',
      contact: 'Contact',
      need_assistance: 'Need assistance?',
      error_404: 'Error 404, page not found!',
      error_title: 'Oops! Something is wrong',
      back_home: 'Go Back to Homepage',

      title_presentation: 'Welcome to my portfolio!',
      presentation: 'Thank you for visiting my digital space. Here you will find a sample of my work and programming projects. I am excited to share with you my passion for programming and how I have applied my skills to solve challenges.',

      hello_world: '👋 Hello world!',
      my_name: 'Eduardo Araya',
      about_me_content: 'Originally from the city of Ovalle in Chile, I am a graduate of Santo Tomas in the career of programmer analyst. I am very passionate about the area of technology and the development and implementation of programming languages.',
      about_me_quote: '“Passion for the unknown is what helps us move forward”',

      santo_tomas_info: 'Graduated in the superior career of programmer analyst, in his professional institute based in Ovalle.',
      santo_tomas_programmer: 'Software design, requirements analysis, advanced programming, computer support.',
      santo_tomas_analysts: 'Implementation and monitoring, maintenance and updating, technology evaluation, project management.',
      systems_analyst: 'Systems analyst',
      udemy_info: 'Udemy has been a tool that has helped me enhance my knowledge and acquire new skills.',
      visit_website: 'Visit their website',
      my_formation: 'My formation',
      about_me_formation: 'About me formation',
      about_me_subtitle: 'My main sources of knowledge',
      my_skills: 'My skills',
      about_skills: 'Main skills that I manage',
      my_strengths: 'My strengths',
      strength_one_title: 'Logical thinking',
      strength_one_description: 'I have a great logical approach when approaching problems and designing the most effective solution possible.',
      strength_two_title: 'Problem resolution',
      strength_two_description: 'I have the ability to analyze and find a complex problem within a solution, using debug tools to find the core of the problem and solve it at its root.',
      strength_three_title: 'Learning capacity',
      strength_three_description: 'I always maintain a great interest in new emerging technologies and in the possibilities of implementing or testing them myself.',
      strength_four_title: 'Time management',
      strength_four_description: 'Having worked on several projects, I can have a general idea of the time needed to solve different problems and manage them in my day to day.',
      
      my_weaknesses: 'My weaknesses',
      weaknesses_one_title: 'Perfectionist approach',
      weaknesses_one_description: 'Personally, I love that things are perfect, that sometimes leads me to spend more time than I should on a task.',
      weaknesses_two_title: 'Low interest in design',
      weaknesses_two_description: 'Design has always been an issue, I\'d rather always be behind the lines of code than design or the UI/UX experience, but I always try to do my best.',
      weaknesses_three_title: 'Excess of self-criticism',
      weaknesses_three_description: 'Sometimes I can be too hard with my codes or come to feel that it does not meet the standard, so I always try to follow already defined structures in programming logic.',

      programmer_analyst: 'Programmer Analyst',
      take_a_walk: 'Take a walk through the different projects I\'ve worked on',
      wanna_see_more: 'Do you want see more?',
      see_all_projects_here: 'See all my projects here!',
      some_languajes: 'Some languajes used...',

      this_proyect_was_made: 'This project was worked with the following technologies and programming languages',
      about_this_proyect: 'About this software project',

      contact_me: 'Contact me',
      looking_for_me: 'Are you lookin for me?',
      looking_for_me_info: 'If you are looking to request a service with me or are interested in offering me a proposal, you can find me on any of my social networks.',

      assistance_data: 'If you have any problem or are looking to contact directly you can write me directly to my personal email:',
      personal_data: 'You can also find me in my personal contact:',
      send_message: 'Send message',
      understood: 'Ok! understood'
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;