import React from 'react'

export const LanguajeBar = ({languaje,percent, barColor = '#CFCFCF', barAccent = '#FFFFFF', textColor= '#FFF'}) => {

    const imgIcon = "src/img/icons/"+languaje+".png";

    const hexToRgbNew = (hex) => {
        var arrBuff = new ArrayBuffer(4);
        var vw = new DataView(arrBuff);
        vw.setUint32(0,parseInt(hex, 16),false);
        var arrByte = new Uint8Array(arrBuff);
        return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
    }

    const rgbColor = hexToRgbNew(barAccent.replace('#',''));

    return (
        <>
            <div className="w-full p-2">
                <div className="flex flex-wrap bg-gray-100 shadow rounded p-2">
                    <div className="w-auto p-2">
                        <img className="big-languaje-icon" src={imgIcon} alt={languaje} />
                    </div>
                    <div className="flex-1 p-2">
                        <h3 className="text-lg font-semibold uppercase">{languaje}</h3>
                        <div className='w-full'>
                            <div className="progress-container">    
                                <div className="progress progress-striped">
                                    <div className="progress-bar" style={{ 
                                        width: percent,
                                        backgroundColor: barColor,
                                        backgroundImage: `linear-gradient(45deg, rgb(${rgbColor}) 25%, transparent 25%, transparent 50%,  rgb(${rgbColor}) 50%, rgb(${rgbColor}) 75%, transparent 75%, transparent)`
                                        
                                        }}>
                                        <div className="progress-percent" style={{ color: textColor}}>{percent}</div>
                                    </div>                       
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
