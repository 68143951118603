import React from 'react'

export const Title = ({text}) => {
  return (
    <>
        <h2 className="
            mb-20 
            text-6xl 
            md:text-8xl 
            text-gray-600 
            xl:text-10xl 
            font-bold 
            font-heading 
            tracking-px-n 
            leading-none
            " 
            data-config-id="auto-txt-1-5">{text}</h2>
    </>
  )
}
