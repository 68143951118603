import React from 'react'

import { Link } from 'react-router-dom';

export const ProyectBanner = ({category,title,subtitle,img,languajes = [],url}) => {

  return (
    <>
      <Link to={"/p/"+url} className="w-full md:w-1/2 p-8 hover:text-gray-800 cursor-pointer">
            <div className="flex flex-wrap lg:items-center -m-4">
              <div className="w-auto p-4">
                <div className="overflow-hidden rounded-xl">
                  <img className="transform hover:scale-105 transition ease-in-out duration-1000" src={img} alt="project_img" style={{width: 162, height: 162}} />
                </div>
              </div>
              <div className="flex-1 p-4">
                <div className="md:max-w-xs">
                  <div className="flex flex-col justify-between h-full">
                    <div className="mb-6">
                      <div className="flex flex-no-wrap items-center mb-1.5 text-sm font-medium uppercase tracking-px">
                        <div className="px-4 bg-blue-800 rounded text-white">
                            {category}
                        </div>
                      </div>
                      <div className="w-full inline-block  text-left">
                        <h3 className="text-xl font-semibold leading-normal">{title}</h3>
                        <p className="text-sm leading-normal">{subtitle}</p>
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center -m-1">
                      {
                        languajes.map((element, index)=>{
                          return <div key={index} className="languaje-proyect-icon">
                            <img src={"src/img/icons/"+element+".png"} alt={element} title={element.toUpperCase()} />
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </Link>
    </>
  )
}
