import React from 'react'
import { useTitle } from '../../hooks/useTitle';

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

const Index = ({selected, imgHeight = "h-72"}) => {

  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18n; // get current languaje

  useTitle('Portfolio - '+selected.title);

  return (
    <>
        <section className="pt-16 pb-4 bg-white overflow-hidden">
                <div className="container px-4 mx-auto">
                    <div className="text-center mx-auto">
                    <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight">{selected.title}</h2>
                    <div className="mb-11 inline-block w-full md:max-w-lg mx-auto">
                        <iframe 
                            width="100%"
                            height="420"
                            src={selected.data.video}
                            title={selected.title}
                            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                    </div>
                </div>
            </div>
        </section>
        <section className="pb-4 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
                <p className="text-lg text-gray-600 text-center font-medium leading-normal mx-auto">{t('this_proyect_was_made')}</p>
                <div className="max-w-6xl mx-auto">
                <div className="flex flex-wrap justify-center">
                    {
                        selected.technologies.map((tech, index)=>{
                            let imgPath = `../src/img/icons/${tech}.png`;
                            return <div key={index} className="w-auto p-8">
                                <img src={imgPath} alt={tech}/>
                            </div>
                        })
                    }
                </div>
                </div>
            </div>
        </section>
        <section className="pt-16 pb-40 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
                <h2 className="mb-4 text-3xl md:text-8xl xl:text-3xl font-bold font-heading text-center tracking-px-n leading-none">{t('about_this_proyect')}</h2>
                <p className="mb-16 text-lg text-gray-600 text-center font-medium leading-normal mx-auto">{(getLanguage() === "es") ? selected.data.presentation.es : selected.data.presentation.en }</p>
                <div className="flex flex-wrap -m-16 md:-m-3">
                    {
                        selected.data.info.map((aptitude,index) =>{
                            return <div key={index} className="w-full md:w-1/3 p-16 md:p-3 mb-32">
                                <div className="px-10 pt-11 text-center bg-gray-100 h-96 rounded-4xl">
                                <h3 className="mb-3 text-xl font-bold font-heading leading-normal">{(getLanguage() === "es") ? aptitude.title.es : aptitude.title.en }</h3>
                                <p className="mb-10 text-gray-600 font-medium leading-relaxed">{(getLanguage() === "es") ? aptitude.description.es : aptitude.description.en }</p>
                                <img className="mx-auto h-72 object-cover rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000" src={aptitude.resource} alt=""/>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            
        </section>

    </>
  )
}

export default Index;