import React from 'react'
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

const Index = () => {

  const { t } = useTranslation();
  
  //const getLanguage = () => i18n.language || window.localStorage.i18n; // get current languaje

  return (
    <>
      <section className="relative pt-28 pb-24 bg-white overflow-hidden" style={{ minHeight: "40vh" }}>
        <div className="relative z-10 container px-4 mx-auto">
          <p className="mb-6 text-sm text-indigo-600 text-center font-semibold uppercase tracking-px">{t('contact_me')}</p>
          <h2 className="mb-20 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight">{t('looking_for_me')}</h2>
          <p className="mb-24 font-medium text-gray-600 text-center leading-relaxed md:max-w-lg mx-auto">{t('looking_for_me_info')}</p>
          <div className="flex flex-wrap -m-8">
            <div className="w-full md:w-1/2 lg:w-1/4 p-8">
              <div className="text-center">
                <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src="src/img/linkedin.png" alt="linkedin" />
                  </div>
                  <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200"></div>
                </div>
                <div className="md:max-w-xs mx-auto">
                  <a className="mb-4 font-heading text-xl font-bold font-heading leading-normal hover:text-blue-600" href="https://www.linkedin.com/in/arayacortesedu/" target="_blank" rel="noreferrer">LinkedIn</a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 p-8">
              <div className="text-center">
                <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <img src="src/img/facebook.png" alt="facebook" />
                  </div>
                  <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200"></div>
                </div>
                <div className="md:max-w-xs mx-auto">
                  <a className="mb-4 font-heading text-xl font-bold font-heading leading-normal hover:text-blue-600" href="https://www.facebook.com/EduardoLeroAraya/" target="_blank" rel="noreferrer">Facebook</a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 p-8">
              <div className="text-center">
                <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src="src/img/instagram.png" alt="instagram" />
                  </div>
                  <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200"></div>
                </div>
                <div className="md:max-w-xs mx-auto">
                  <a className="mb-4 font-heading text-xl font-bold font-heading leading-normal hover:text-blue-600" href="https://www.instagram.com/lero.araya/" target="_blank" rel="noreferrer">Instagram</a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 p-8">
              <div className="text-center">
                <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                  <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src="src/img/github.png" alt="github" />
                  </div>
                </div>
                <div className="md:max-w-xs mx-auto">
                  <a className="mb-4 font-heading text-xl font-bold font-heading leading-normal hover:text-blue-600" href="https://github.com/Leroncio" target="_blank" rel="noreferrer">Github</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Index;
