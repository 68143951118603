import React from 'react'
import '../lang/i18n';
import {  Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Footer = () => {

    const { t } = useTranslation();
    
    const faceUrl = window.location.protocol.concat("//").concat(window.location.host).concat("/src/img/b-facebook.png");
    const gitUrl = window.location.protocol.concat("//").concat(window.location.host).concat("/src/img/b-github.png");
    const linkedinUrl = window.location.protocol.concat("//").concat(window.location.host).concat("/src/img/b-linkedin.png");

  return (
    <>
        <section className="relative py-16 bg-white overflow-hidden">
        <img className="absolute top-0 left-0 h-full" src="flaro-assets/images/footers/gradient4.svg" alt="" />
        <div className="relative z-10 container px-4 mx-auto">
            <div className="flex flex-wrap justify-between items-center -m-8">
            <div className="w-auto p-8"><Link to="/">PORTFOLIO</Link></div>
            <div className="w-auto p-8">
                <ul className="flex flex-wrap items-center -m-5">
                <li className="p-5"><Link className='text-gray-600 hover:text-gray-700 font-medium' to="/">{t('home')}</Link></li>
                <li className="p-5"><Link className='text-gray-600 hover:text-gray-700 font-medium' to="/proyects">{t('my_projects')}</Link></li>
                <li className="p-5"><Link className='text-gray-600 hover:text-gray-700 font-medium' to="/about-me">{t('about_me')}</Link></li>
                <li className="p-5"><Link className='text-gray-600 hover:text-gray-700 font-medium' to="/contact">{t('contact')}</Link></li>
                </ul>
            </div>
            <div className="w-auto p-8">
                <div className="flex flex-wrap -m-1.5">
                <div className="w-auto p-1.5">
                    <a href="https://www.facebook.com/EduardoLeroAraya" target='_blank' rel="noreferrer">
                    <div className="flex items-center justify-center w-8 h-8 border border-gray-300 hover:border-gray-400 rounded-full">
                        <img src={faceUrl} alt='facebook_link' />
                    </div>
                    </a>
                </div>
                <div className="w-auto p-1.5">
                    <a href="https://github.com/Leroncio" target='_blank' rel="noreferrer">
                    <div className="flex items-center justify-center w-8 h-8 border border-gray-300 hover:border-gray-400 rounded-full">
                        <img src={gitUrl} alt='github_link' />
                    </div>
                    </a>
                </div>
                <div className="w-auto p-1.5">
                    <a href="https://www.linkedin.com/in/arayacortesedu" target='_blank' rel="noreferrer">
                    <div className="flex items-center justify-center w-8 h-8 border border-gray-300 hover:border-gray-400 rounded-full">
                        <img src={linkedinUrl} alt='linkedin_link' />
                    </div>
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
    </>
  )
}