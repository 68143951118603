import React, { useState } from 'react'
import '../lang/i18n';
import {  Outlet, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Header = () => {

    const { t, i18n } = useTranslation();

    let [isOpen, switchModal ] = useState(false);
    let [mobileMenu, switchMenu] = useState(false);

    const mainEsImgUrl = window.location.protocol.concat("//").concat(window.location.host).concat("/src/img/es.png");
    const mainEnImgUrl = window.location.protocol.concat("//").concat(window.location.host).concat("/src/img/en.png");
    const wpImg = window.location.protocol.concat("//").concat(window.location.host).concat("/src/img/whatsapp.png");

    const updateModal = (status) =>{
      switchModal(status);
    }

    const showMenu = (status) =>{
      switchMenu(status);
    }

    return (
      <>
          {/*
          <div className='background-video'>
              <video autoPlay loop muted className="relative rounded-7xl" poster="#">
                  <source src="src/video/tech-loop.mp4" type="video/mp4" />
              </video>
          </div>
          */}
          <div className="container mx-auto overflow-hidden">
              <div className="relative z-20 flex items-center justify-between px-4 py-5 bg-transparent" data-config-id="toggle-mobile" data-config-target=".navbar-menu" data-config-class="hidden">
                <div className="w-auto">
                  <div className="flex flex-wrap items-center">
                    <div className="w-auto mr-14">
                      <Link to="/">PORTFOLIO</Link>
                    </div>
                    <div>
                    <div className="w-auto hidden lg:block">
                        <ul className="flex items-center mr-16">
                          <li className="mr-9 font-medium hover:text-gray-700"><Link to="/">{t('home')}</Link></li>
                          <li className="mr-9 font-medium hover:text-gray-700"><Link to="/proyects">{t('my_projects')}</Link></li>
                          <li className="mr-9 font-medium hover:text-gray-700"><Link to="/about-me">{t('about_me')}</Link></li>
                          <li className="font-medium hover:text-gray-700"><Link to="/contact">{t('contact')}</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-auto">
                  <div className="flex flex-wrap items-center">
                    <div className="w-auto hidden mr-5 lg:block">
                      <div className="inline-block">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => i18n.changeLanguage('es')}
                        disabled={i18n.language === 'es'}
                      >
                        <img src={mainEsImgUrl} className='img-flag' alt={t('spanish')} title={t('spanish')}></img>
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => i18n.changeLanguage('en')}
                        disabled={i18n.language === 'en'}
                      >
                        <img src={mainEnImgUrl} className='img-flag' alt={t('english')} title={t('english')}></img>
                      </button>
                      </div>
                    </div>
                    <div className="w-auto hidden lg:block">
                      <div className="inline-block">
                        <button className="py-3 px-5 w-full font-semibold border hover:border-gray-300 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200" type="button" data-config-id="auto-txt-6-3" onClick={ ()=>updateModal(true) }>{t('need_assistance')}</button>
                      </div>
                    </div>
                    <div className="w-auto lg:hidden">
                      <div>
                        <svg onClick={()=>showMenu(true)} className="navbar-burger cursor-pointer text-indigo-600" width="51" height="51" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-1-3">
                          <rect width="56" height="56" rx="28" fill="currentColor"></rect>
                          <path d="M37 32H19M37 24H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={ mobileMenu ? "" : "hidden" }>
                <div className="navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
                  <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80" onClick={ ()=>showMenu(false) }></div>
                  <nav className="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto" data-config-id="toggle-mobile-2" data-config-target=".navbar-menu" data-config-class="hidden">
                    <div className="flex flex-wrap justify-between h-full">
                      <div className="w-full">
                        <div className="flex items-center justify-between -m-2">
                          <div className="w-auto p-2">
                            <Link to="/">PORTFOLIO</Link>
                          </div>
                          <div className="w-auto p-2">
                            <div className="navbar-burger" onClick={ ()=>showMenu(false) }>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-2-3">
                                <path d="M6 18L18 6M6 6L18 18" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center py-16 w-full">
                        <ul>
                          <li className="mb-12"><Link className="font-medium hover:text-gray-700" to="/">{t('home')}</Link></li>
                          <li className="mb-12"><Link className="font-medium hover:text-gray-700" to="/proyects">{t('my_projects')}</Link></li>
                          <li className="mb-12"><Link className="font-medium hover:text-gray-700" to="/about-me">{t('about_me')}</Link></li>
                          <li className="mb-12"><Link className="font-medium hover:text-gray-700" to="/contact">{t('contact')}</Link></li>
                        </ul>
                      </div>
                      <div className="flex flex-col justify-end w-full pb-8">
                        <div className="flex justify-center flex-wrap">
                        <div className="w-auto mr-5 lg:block">
                          <div className="inline-block">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => i18n.changeLanguage('es')}
                            disabled={i18n.language === 'es'}
                          >
                            <img src={mainEsImgUrl} className='img-flag' alt={t('spanish')} title={t('spanish')}></img>
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={() => i18n.changeLanguage('en')}
                            disabled={i18n.language === 'en'}
                          >
                            <img src={mainEnImgUrl} className='img-flag' alt={t('english')} title={t('english')}></img>
                          </button>
                          </div>
                        </div>

                          <div className="w-full mb-3">
                            <div className="block">
                              <button className="py-3 px-5 w-full font-semibold border hover:border-gray-300 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200" type="button" data-config-id="auto-txt-6-3" onClick={ ()=>updateModal(true) }>{t('need_assistance')}</button>                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <section className={ isOpen ? "" : "hidden" }>
              <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50" style={{ zIndex: 200 }}>
                <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3">
                  <div className="border-b px-4 py-2 flex justify-between items-center">
                    <h3 className="font-semibold text-lg p-4 text-center w-full">{t('need_assistance')}</h3>
                  </div>
                  <div className="p-8">
                    <p>{t('assistance_data')}</p>
                    <div className='my-8 text-gray-800'>
                      <span>a</span>
                      <span>r</span>
                      <span>a</span>
                      <span>y</span>
                      <span>a</span>
                      <span>c</span>
                      <span>o</span>
                      <span>r</span>
                      <span>t</span>
                      <span>e</span>
                      <span>s</span>
                      <span>.</span>
                      <span>e</span>
                      <span>d</span>
                      <span>u</span>
                      <span>@</span>
                      <span>g</span>
                      <span>m</span>
                      <span>a</span>
                      <span>i</span>
                      <span>l</span>
                      <span>.</span>
                      <span>c</span>
                      <span>o</span>
                      <span>m</span>
                    </div>
                    <p>{t('personal_data')}</p>
                    <div className='flex justify-center mt-8'>
                      <a className='flex text-white bg-green-700 rounded py-1 px-2' href='https://wa.me/5491166798295' target="_blank" rel="noreferrer">
                        <img src={wpImg} className='wp-icon' alt="whatsaap" /><span className='wp-text'>{t('send_message')}</span>
                      </a>
                    </div>
                  </div>
                  <div className="flex justify-end items-center w-100 border-t p-3">
                    <button className="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal" onClick={ ()=>updateModal(false) }>{t('understood')}</button>
                  </div>
                </div>
              </div>
            </section>

          <Outlet />
      </>
            
  )
}