import React from 'react'
import { Hero } from './Hero';
import { Study } from './Study';
import { Proyects } from './Proyects';

const index = () => {
    return (
      <div>
        <Hero />
        <Study />
        <Proyects />
      </div>
    )
  }
  
  export default index;
  
