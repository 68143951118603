import React from 'react'
import { Title } from '../../components/Title'
import { ProyectCard } from '../Proyects/ProyectCard'

import data from '../../data.json';
import {  Link } from 'react-router-dom';
import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

export const Proyects = () => {

  const { t,i18n } = useTranslation();

  const getRamdonProyects = (array)=>{
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array.slice(0, 3);
  }

  const getLanguage = () => i18n.language || window.localStorage.i18n; // get current languaje

  return (
    <>
        <section className="pt-24 pb-40 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
                <Title text={t('my_projects')} />
                <div className="flex flex-wrap -m-16 md:-m-3">
                    {
                      getRamdonProyects(data).map((element,index)=>{
                        return <ProyectCard 
                                  key={index} 
                                  title={element.title} 
                                  subtitle={(getLanguage() === "es") ? element.description.es : element.description.en }
                                  imgSrc={element.image}
                                  url={element.url}
                                />
                      })
                    }
                </div>
            </div>
        </section>

        <section className="pt-16 pb-16 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="text-center max-w-lg mx-auto">
              <h2 className="mb-5 text-10xl md:text-6xl font-bold font-heading text-center tracking-px-n leading-tight">{t('wanna_see_more')}</h2>
              <p className="mb-7 text-lg text-gray-600 font-medium">{t('take_a_walk')}</p>
              <div className="mb-11 md:inline-block">
                <Link className='py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200' to="/proyects">{t('see_all_projects_here')}</Link>
              </div>
              <div className="flex flex-wrap items-center justify-center -m-1 mt-8">
                <div className="w-auto p-1">
                  <div className="flex flex-wrap">
                    <div className="w-auto">
                      <div className='languaje-icon'>
                        <img src="src/img/icons/php.png" alt="php" />
                      </div>
                    </div>
                    <div className="w-auto -ml-3">
                      <div className='languaje-icon'>
                        <img src="src/img/icons/java.png" alt="java" />
                      </div>
                    </div>
                    <div className="w-auto -ml-3">
                      <div className='languaje-icon'>
                        <img src="src/img/icons/react.png" alt="react" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-auto p-1">
                  <p className="text-gray-600 font-medium">
                    <span>{t('some_languajes')}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}
