import React from 'react'
import { ProyectBanner } from './ProyectBanner';
import data from '../../data.json';

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';

const Index = () => {

  const { t, i18n } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18n; // get current languaje

  return (
    <>
    <section className="pt-24 relative pb-36 overflow-hidden">
      <div className="container px-4 mx-auto">
        <h2 className="mb-4 text-6xl md:text-7xl text-center font-bold font-heading font-heading tracking-px-n leading-tight">{t('my_projects')}</h2>
        <p className="mb-24 font-medium text-gray-500 text-center leading-relaxed md:max-w-lg mx-auto">{t('my_projects_info')}</p>
        <div className="flex flex-wrap -m-8">
          {
            data.map((element, index)=>{
                return <ProyectBanner 
                  key={index} 
                  category={element.category} 
                  title={element.title} 
                  subtitle={(getLanguage() === "es") ? element.description.es : element.description.en }
                  img={element.image}
                  languajes={element.technologies}
                  url={element.url}
                />
            })
          }
        </div>
      </div>
    </section>
    </>
  )
}

export default Index;