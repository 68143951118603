import './App.css';
//import { useEffect,useState } from "react";
import './Stylesheets/tailwind/tailwind.min.css';
import {  Routes, Route } from 'react-router-dom';

import Home from './views/Home';
import Proyects from './views/Proyects';
import AboutMe from './views/AboutMe';
import Contact from './views/Contact';
import Proyect from './views/Proyect';

import Error404 from './views/Error/404';

import { Header as MainHeader} from './components/Header';
import { Footer } from './components/Footer';

import data from './data.json';

//import axios from "axios";

 function App() {

  //const [response, setData] = useState(null);
  //const [isLoading, setIsLoading] = useState(true);

  /*
  useEffect(() => {
    axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=65cb9f486d594287b6a18cf22bda0134')
      .then(response => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }, []);*/

  return (
    <div className="App">

                <Routes>
                <Route path="/" element={<MainHeader />}>
                  <Route index element={<Home />} />
                  <Route path="proyects" element={<Proyects />} />
                  <Route path="about-me" element={<AboutMe />} />
                  <Route path="contact" element={<Contact />} />
                  {
                    data.map((element, index)=>{
                      return <Route 
                        key={index} 
                        path={"p/"+element.url}
                        element={<Proyect selected={element} />}
                      />
                    })              
                  }

                  <Route path="*" element={<Error404 />} />
                </Route>

                </Routes>

                <Footer />


      {/*isLoading ? (
        <></>
      ) : (
        <div>
          {
            (response.country_code === "CL") ? (
            <>
              MAINTENANCE
            </>
            ) : 
            <>
              
            </>
          }
        </div>
        )*/}

    </div>
  );
}

export default App;
