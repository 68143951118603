import React from 'react'
import { Link } from 'react-router-dom';

export const ProyectCard = ({ title, subtitle, imgSrc, url }) => {
  return (
    <>
        <Link to={"/p/"+url} className="w-full md:w-1/3 p-16 md:p-3 mb-20 cursor-pointer">
              <div className="px-10 pt-11 text-center bg-gray-100 h-96 rounded-4xl">
              <h3 className="mb-3 text-xl font-bold font-heading leading-normal">{title}</h3>
              <p className="mb-4 text-gray-600 font-medium leading-relaxed">{subtitle}</p>
              <img className="w-full m-0 h-86 object-cover rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000" src={imgSrc} alt="proyect_card_img" />
              </div>
        </Link>
    </>
  )
}
